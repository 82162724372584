import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getCategoriesList(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/catalog/category/index",
      params
    );
  },
  addCategories(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/catalog/category/store",
      params
    );
  },
  updateCategories(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/catalog/category/update",
      params
    );
  },
  deleteCategories(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/catalog/category/delete",
      params
    );
  },
  getCategoriesInfo(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/catalog/category/show",
      params
    );
  },
  getCategoriesSelectInfo(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/catalog/category/notSubsetShow",
      params
    );
  },
  getCategorySourceData(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/data-source/category",
      params
    );
  },
};
