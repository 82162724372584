import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getGroupList(params) {
    return ApiService.post(Config.merchantApiUrl + "/api/group/index", params);
  },
  addGroup(params) {
    return ApiService.post(Config.merchantApiUrl + "/api/group/store", params);
  },
  updateGroup(params) {
    return ApiService.post(Config.merchantApiUrl + "/api/group/update", params);
  },
  deleteGroup(params) {
    return ApiService.post(Config.merchantApiUrl + "/api/group/delete", params);
  },
  getGroupInfo(params) {
    return ApiService.post(Config.merchantApiUrl + "/api/group/show", params);
  },
  groupPrint(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/group/export",
      params,
      { responseType: "blob" }
    );
  },
};
