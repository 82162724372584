/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-08-02 18:05:08
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-09-27 10:58:51
 * @FilePath: /merchant-frontend/src/core/config/ApiConfig.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const config = {
  baseApiUrl: process.env.VUE_APP_BASE_API_URL,
  merchantApiUrl: process.env.VUE_APP_MERCHANT_API_URL,
  distributionApiUrl: process.env.VUE_APP_DISTRIBUTION_API_URL,
  fulfillmentApiUrl: process.env.VUE_APP_FULFILLMENT_API_URL,
  settlementApiUrl: process.env.VUE_APP_SETTLEMENT_API_URL,
  pimApiUrl: process.env.VUE_APP_PIM_API_URL,
  cdnUrl: process.env.VUE_APP_CDN_URL,
};

export default config;
