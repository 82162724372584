import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getPosList(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/channel/pos/index",
      params
    );
  },
  addPos(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/channel/pos/store",
      params
    );
  },
  updatePos(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/channel/pos/update",
      params
    );
  },
  deletePos(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/channel/pos/delete",
      params
    );
  },
  getPosInfo(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/channel/pos/show",
      params
    );
  },
  posPrint(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/channel/pos/export",
      params,
      { responseType: "blob" }
    );
  },
};
