import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getStoreList(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/channel/store/index",
      params
    );
  },
  addStore(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/channel/store/store",
      params
    );
  },
  updateStore(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/channel/store/update",
      params
    );
  },
  deleteStore(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/channel/store/delete",
      params
    );
  },
  getStoreInfo(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/channel/store/show",
      params
    );
  },
  storePrint(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/channel/store/export",
      params,
      { responseType: "blob" }
    );
  },
};
