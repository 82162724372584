import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getCompanyList(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/index",
      params
    );
  },
  addCompany(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/store",
      params
    );
  },
  updateCompany(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/update",
      params
    );
  },
  deleteCompany(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/delete",
      params
    );
  },
  getCompanyInfo(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/show",
      params
    );
  },
  getCompanyChanelList(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/channel/index",
      params
    );
  },
  getGroupData(params?) {
    params = params ?? { max_item: "all" };
    return ApiService.post(
      Config.merchantApiUrl + "/api/data-source/group",
      params
    );
  },

  getFiscalHistoryList(params?) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fiscal-history/index",
      params
    );
  },
  addFiscalHistory(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fiscal-history/store",
      params
    );
  },
  updateFiscalHistory(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fiscal-history/update",
      params
    );
  },
  deleteFiscalHistory(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fiscal-history/delete",
      params
    );
  },
  getFiscalHistoryInfo(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fiscal-history/show",
      params
    );
  },

  getMerchantPimData(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/pim/show",
      params
    );
  },
  updateMerchantPimData(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/pim/update",
      params
    );
  },

  getMerchantRelationshipList(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/relation/index",
      params
    );
  },
  addMerchantRelationship(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/relation/store",
      params
    );
  },
  updateMerchantRelationship(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/relation/update",
      params
    );
  },
  deteleMerchantRelationship(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/relation/delete",
      params
    );
  },
  getMerchantRelationshipInfo(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/relation/show",
      params
    );
  },
  getMerchantPimInventoryRuleList(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/pim/inventory-rule/index",
      params
    );
  },
  getMerchantPimInventoryRuleData(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/pim/inventory-rule/show",
      params
    );
  },
  addMerchantPimInventoryRule(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/pim/inventory-rule/store",
      params
    );
  },
  updateInventoryRule(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/pim/inventory-rule/update",
      params
    );
  },
  deleteInventoryRule(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/pim/inventory-rule/delete",
      params
    );
  },
  getMerchantDistributionData(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/distribution/show",
      params
    );
  },
  updateMerchantDistributionData(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/distribution/update",
      params
    );
  },
  getMerchantDistributionPriceRuleList(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/distribution/price-rule/index",
      params
    );
  },
  getMerchantDistributionPriceRuleData(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/distribution/price-rule/show",
      params
    );
  },
  addMerchantDistributionPriceRule(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/distribution/price-rule/store",
      params
    );
  },
  updatePriceRule(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/distribution/price-rule/update",
      params
    );
  },
  deletePriceRule(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/distribution/price-rule/delete",
      params
    );
  },
  getMerchantFulfillmentData(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fulfillment/show",
      params
    );
  },
  updateMerchantFulfillmentData(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fulfillment/update",
      params
    );
  },
  getMerchantPathList(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fulfillment/merchant-path/show",
      params
    );
  },
  updateMerchantPath(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fulfillment/merchant-path/update",
      params
    );
  },
  merchantsPrint(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/export",
      params,
      { responseType: "blob" }
    );
  },
  channelDataSource(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/data-source/channel",
      params
    );
  },
};
