import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import {
  MBLayout,
  MBAuth,
  MBSignIn,
  MBError404,
  MBError500,
} from "magicbean-saas-common";
import merchantRoutes from "./merchant";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: MBLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      ...merchantRoutes,

      {
        path: "/merchant-network/leads",
        name: "leads",
        component: () => import("@/views/merchant-network/leads/Index.vue"),
      },
      {
        path: "/merchant-network/leads/:id",
        name: "leads-information",
        component: () =>
          import("@/views/merchant-network/leads/Information.vue"),
      },

      {
        path: "/sales-channels/point-of-sale",
        name: "pos",
        component: () =>
          import("@/views/sales-channels/point-of-sale/Index.vue"),
      },

      {
        path: "/sales-channels/point-of-sale/:id/Information",
        name: "pos-information",
        component: () =>
          import("@/views/sales-channels/point-of-sale/Information.vue"),
      },
      {
        path: "/sales-channels/mobile-applications",
        name: "mobile-applications",
        component: () =>
          import("@/views/sales-channels/mobile-applications/Index.vue"),
      },
      {
        path: "/sales-channels/mobile-applications/:id/Information",
        name: "app-information",
        component: () =>
          import("@/views/sales-channels/mobile-applications/Information.vue"),
      },
      {
        path: "/sales-channels/online-stores",
        name: "online-store",
        component: () =>
          import("@/views/sales-channels/online-stores/Index.vue"),
      },
      {
        path: "/merchant-network/groups",
        name: "groups",
        component: () => import("@/views/merchant-network/groups/Index.vue"),
      },
      {
        path: "/merchant-network/groups/:id/Information",
        name: "group-information",
        component: () =>
          import("@/views/merchant-network/groups/Information.vue"),
      },
      {
        path: "/sales-channels/online-stores/:id/overview",
        name: "store-overview",
        component: () =>
          import("@/views/sales-channels/online-stores/Overview.vue"),
      },
      {
        path: "/sales-channels/online-stores/:id/Information",
        name: "store-information",
        component: () =>
          import("@/views/sales-channels/online-stores/Information.vue"),
      },
      // Contacts
      {
        path: "/contacts",
        name: "contacts",
        component: () => import("@/views/contacts/Index.vue"),
      },
      {
        path: "/contacts/:id/Information",
        name: "contacts-information",
        component: () => import("@/views/contacts/Information.vue"),
      },
    ],
  },
  {
    path: "/",
    component: MBAuth,
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: MBSignIn,
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: MBError404,
  },
  {
    path: "/500",
    name: "500",
    component: MBError500,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  if (to.meta.contentWidth == "fluid") {
    store.commit(Mutations.OVERRIDE_PAGE_LAYOUT_CONFIG, {
      content: {
        width: "fluid",
      },
    });
  }

  if (to.name !== "sign-in") {
    store.dispatch(Actions.VERIFY_AUTH);
    store.dispatch(Actions.GET_MENU_ITEMS, to.path);
    next();
  } else {
    next();
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
