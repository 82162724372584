/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-08-02 18:05:08
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-02-09 13:47:58
 * @FilePath: /merchant-frontend/src/core/api/contacts.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getContactList(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/contact/index",
      params
    );
  },
  addContact(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/contact/store",
      params
    );
  },
  updateContact(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/contact/update",
      params
    );
  },
  deleteContact(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/contact/delete",
      params
    );
  },
  getContactInfo(params) {
    return ApiService.post(Config.merchantApiUrl + "/api/contact/show", params);
  },
  getContactData(params?) {
    params = params ?? { max_item: "all" };
    return ApiService.post(
      Config.merchantApiUrl + "/api/data-source/contact",
      params
    );
  },
  getPositionData(params?) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/data-source/position",
      params
    );
  },
  getDepartmentData(params?) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/data-source/department",
      params
    );
  },
  contactPrint(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/contact/export",
      params,
      { responseType: "blob" }
    );
  },
};
