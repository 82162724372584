export default [
  {
    path: "/merchants",
    name: "merchants-main",
    component: () => import("@/views/merchants/Index.vue"),
  },
  {
    path: "/merchants/:id",
    name: "merchant-detail",
    component: () => import("@/views/merchants/Detail.vue"),
    children: [
      {
        path: "",
        redirect: "merchants-overview",
      },
      {
        path: "overview",
        name: "merchants-overview",
        component: () => import("@/views/merchants/Overview.vue"),
      },
      {
        path: "information",
        name: "merchants-information",
        component: () => import("@/views/merchants/Information.vue"),
      },
      {
        path: "channels",
        name: "merchants-sales-channels",
        component: () => import("@/views/merchants/SalesChannels.vue"),
      },
      {
        path: "contacts",
        name: "merchants-contacts",
        component: () => import("@/views/merchants/Contacts.vue"),
      },
      {
        path: "pim",
        name: "merchants-pim",
        component: () => import("@/views/merchants/PIM.vue"),
      },
      {
        path: "distribution",
        name: "merchants-distribution",
        component: () => import("@/views/merchants/Distribution.vue"),
      },
      {
        path: "fulfillment",
        name: "merchants-fulfillment",
        component: () => import("@/views/merchants/Fulfillment.vue"),
      },
      {
        path: "settlement",
        name: "merchants-settlement",
        component: () => import("@/views/merchants/Settlement.vue"),
      },
    ],
  },

  {
    path: "/merchant-network/offline-merchants",
    name: "offline-merchants",
    component: () =>
      import("@/views/merchant-network/offline-merchants/Index.vue"),
  },
  {
    path: "/merchant-network/offline-merchants/:id",
    name: "offline-merchant-detail",
    component: () =>
      import("@/views/merchant-network/offline-merchants/Detail.vue"),
    children: [
      {
        path: "overview",
        name: "offline-merchants-overview",
        component: () => import("@/views/merchants/Overview.vue"),
      },
      {
        path: "information",
        name: "offline-merchants-information",
        component: () => import("@/views/merchants/Information.vue"),
      },
      {
        path: "channels",
        name: "offline-merchants-sales-channels",
        component: () => import("@/views/merchants/SalesChannels.vue"),
      },
      {
        path: "contacts",
        name: "offline-merchants-contacts",
        component: () => import("@/views/merchants/Contacts.vue"),
      },
      {
        path: "pim",
        name: "offline-merchants-pim",
        component: () => import("@/views/merchants/PIM.vue"),
      },
      {
        path: "distribution",
        name: "offline-merchants-distribution",
        component: () => import("@/views/merchants/Distribution.vue"),
      },
      {
        path: "fulfillment",
        name: "offline-merchants-fulfillment",
        component: () => import("@/views/merchants/Fulfillment.vue"),
      },
      {
        path: "settlement",
        name: "offline-merchants-settlement",
        component: () => import("@/views/merchants/Settlement.vue"),
      },
    ],
  },
];
