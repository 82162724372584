/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-08-02 18:05:08
 * @LastEditors: Json Weng dinglong.weng@travel-easy.com
 * @LastEditTime: 2024-07-18 16:57:06
 * @FilePath: /merchant-frontend/src/core/api/base.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getMenuConfig() {
    return ApiService.post(Config.baseApiUrl + "/api/config/menu", {});
  },

  getCountryData() {
    return ApiService.post(
      Config.baseApiUrl + "/api/data-source/global-data/country",
      {}
    );
  },

  getPhoneCodeData() {
    return ApiService.post(
      Config.baseApiUrl + "/api/data-source/global-data/country-phone-code",
      {}
    );
  },

  getTaggingData(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/data-source/tagging/tag",
      params
    );
  },

  getTaggingGroupData(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/data-source/tagging/group",
      params
    );
  },

  getBrandSourceData(params) {
    return ApiService.post(Config.pimApiUrl + "/api/data-source/brand", params);
  },

  getCategorySourceData(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/data-source/category",
      params
    );
  },

  getMerchantSourceData(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/data-source/merchant",
      params
    );
  },

  getCustomerSourceData(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/basic/data-source/customers",
      params
    );
  },

  getUserSourceData(params) {
    return ApiService.post(Config.baseApiUrl + "/api/data-source/user", {
      status: 10,
      ...params,
    });
  },

  getAccountSourceData(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/data-source/account/name",
      params
    );
  },

  getInfluencerGroupSourceData(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/data-source/account/influencer-group",
      params
    );
  },

  getWarehouseData(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/basic/data-source/warehouse",
      {
        active: 1,
        ...params,
      }
    );
  },

  getUserOperatingEntity(params?) {
    return ApiService.post(
      Config.baseApiUrl + "/api/authorize/me/operating-entity",
      params
    );
  },

  getSsoApps(params) {
    return ApiService.post(Config.baseApiUrl + "/api/sso/apps", params);
  },

  getSsoAppUrl(url, params) {
    return ApiService.post(url, params);
  },
};
