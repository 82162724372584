import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getAppList(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/channel/app/index",
      params
    );
  },
  addApp(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/channel/app/store",
      params
    );
  },
  updateApp(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/channel/app/update",
      params
    );
  },
  deleteApp(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/channel/app/delete",
      params
    );
  },
  getAppInfo(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/channel/app/show",
      params
    );
  },
  appPrint(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/channel/app/export",
      params,
      { responseType: "blob" }
    );
  },
};
